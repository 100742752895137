import { type PropsWithChildren } from 'react';
import { Button, type ButtonProps } from 'react-aria-components';

type Props = PropsWithChildren<ButtonProps>;

export function TextButton(props: Props) {
  return (
    <Button {...props} className="p-[2px] disabled:opacity-50">
      <p className="m-0 text-center text-[14px] font-normal text-[#00000080]">{props.children}</p>
    </Button>
  );
}
